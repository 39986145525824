// Internal
import {
  Insurance,
  PatientState,
  Clinic,
  Events,
  LastSchedule,
} from 'app/state/schedule/types';
import * as types from './constants';

export const fetchEvents = (
  start_date: string,
  end_date: string,
) => ({
  type: types.FETCH_EVENTS,
  payload: {
    start_date,
    end_date,
  },
} as const);

export const fetchEventsSuccess = (payload: Events) => ({
  type: types.FETCH_EVENTS_SUCCESS,
  payload,
} as const);

export const fetchConfigurations = () => ({
  type: types.FETCH_CONFIGURATIONS,
} as const);

export const fetchConfigurationsEvents = (
  start_date: string,
  end_date: string,
) => ({
  type: types.FETCH_CONFIGURATIONS_EVENTS,
  payload: {
    start_date,
    end_date,
  },
} as const);

export const fetchConfigurationsEventsError = (message: string) => ({
  type: types.FETCH_CONFIGURATIONS_EVENTS_ERROR,
  payload: {
    message,
  },
} as const);

export const fetchConfigurationsSuccess = (payload: Clinic[]) => ({
  type: types.FETCH_CONFIGURATIONS_SUCCESS,
  payload,
} as const);

export const fetchInsurancesClinic = (
  clinic_id: number,
) => ({
  type: types.FETCH_INSURANCES_CLINIC,
  payload: {
    clinic_id,
  },
} as const);

export const fetchInsurancesClinicSuccess = (payload: Insurance[]) => ({
  type: types.FETCH_INSURANCES_CLINIC_SUCCESS,
  payload,
} as const);

export const fetchSaveEvent = (payload: PatientState) => ({
  type: types.FETCH_SAVE_EVENT,
  payload,
} as const);

export const fetchResendEvent = (payload: PatientState) => ({
  type: types.FETCH_RESEND_EVENT,
  payload,
} as const);

export const fetchSaveEventSuccess = (token: string) => ({
  type: types.FETCH_SAVE_EVENT_SUCCESS,
  payload: {
    token,
  },
} as const);

export const fetchConfirmEvent = (code: string) => ({
  type: types.FETCH_CONFIRM_EVENT,
  params: {
    code,
  },
} as const);

export const fetchConfirmEventSuccess = () => ({
  type: types.FETCH_CONFIRM_EVENT_SUCCESS,
} as const);

export const goToFirstStep = () => ({
  type: types.GO_TO_FIRST_STEP,
} as const);

export const changeClinicId = (clinic_id: number) => ({
  type: types.CHANGE_CLINIC_ID,
  payload: {
    clinic_id,
  },
} as const);

export const changeAttendanceType = (attendancePerson: boolean) => ({
  type: types.CHANGE_ATTENDANCE,
  payload: {
    attendancePerson,
  },
} as const);

export const hideSchedule = () => ({
  type: types.HIDE_SCHEDULE,
} as const);

export const toggleModalVisible = () => ({
  type: types.TOGGLE_MODAL_VISIBLE,
} as const);

export const openModal = () => ({
  type: types.OPEN_MODAL,
} as const);

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
} as const);

export const clearStepLoading = () => ({
  type: types.CLEAR_STEP_LOADING,
} as const);

export const putDefaultClinicId = (clinic_id: number) => ({
  type: types.PUT_DEFAULT_CLINIC_ID,
  payload: {
    clinic_id,
  },
} as const);

export const fetchLastSchedules = () => ({
  type: types.FETCH_LAST_SCHEDULE,
} as const);


export const fetchLastSchedulesSuccess = (payload: LastSchedule) => ({
  type: types.FETCH_LAST_SCHEDULE_SUCCESS,
  payload,
} as const);
