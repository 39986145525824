// Internal
import { PROFILE } from './types';

export const signUp = (payload) => ({ type: PROFILE.SIGNUP, payload });
export const fetchBySlug = (slug) => ({ type: PROFILE.BY_SLUG, slug });
export const fetchById = (id) => ({ type: PROFILE.BY_ID, id });
export const fetchCbos = () => ({ type: PROFILE.FETCH_CBOS });
export const fetchPartner = (id) => ({ type: PROFILE.FETCH_PARTNER, id });
export const fetchCbosSuccess = (payload) => ({
  type: PROFILE.FETCH_CBOS_SUCCESS,
  payload,
});
export const fetchPartnerSuccess = (payload) => ({
  type: PROFILE.FETCH_PARTNER_SUCCESS,
  payload,
});
export const fetchFailure = (message) => ({
  type: PROFILE.FETCH_FAILURE,
  message,
});
export const fetchFailureSignup = (errors, message) => ({
  type: PROFILE.FETCH_FAILURE_SIGNUP,
  errors,
  message,
});
export const fetchSuccess = (payload) => ({
  type: PROFILE.FETCH_SUCCESS,
  payload,
});
export const fetchSuccessSignup = (message) => ({
  type: PROFILE.FETCH_SUCCESS_SIGNUP,
  message,
});
export const fetchSuccessServices = (payload) => ({
  type: PROFILE.FETCH_SUCCESS_SERVICES,
  payload,
});
export const setProfile = ({ profile }) => ({
  type: PROFILE.SET,
  payload: { profile },
});
export const reset = () => ({ type: PROFILE.RESET });

export const saveProfile = (data) => ({
  type: PROFILE.SAVE,
  payload: { data },
});
export const profileUpdate = (data) => ({
  type: PROFILE.UPDATE,
  payload: { data },
});
export const listAdd = (entity, data) => ({
  type: PROFILE.LIST_ADD,
  payload: { entity, data },
});
export const listRemove = (entity, id) => ({
  type: PROFILE.LIST_REMOVE,
  payload: { entity, id },
});
export const listUpdate = (entity, data) => ({
  type: PROFILE.LIST_UPDATE,
  payload: { entity, data },
});

export const fetchBulkUpdate = (payload) => ({
  type: PROFILE.BULK_UPDATE,
  payload,
});

export const listUpdateInsuraces = (insurances) => ({
  type: PROFILE.LIST_UPDATE_INSURANCES,
  payload: {
    insurances,
  },
});

export const acceptServiceTerms = (term) => ({
  type: PROFILE.ACCEPT_SERVICE_TERMS,
  payload: {
    term,
    is_fetching: true,
  },
});

export const acceptServiceTermsSuccess = () => ({
  type: PROFILE.ACCEPT_SERVICE_TERMS_SUCCESS,
  payload: {
    is_fetching: false,
  },
});

export const acceptServiceTermsFailure = (errors, message) => ({
  type: PROFILE.ACCEPT_SERVICE_TERMS_FAILURE,
  payload: {
    is_fetching: false,
    errors,
    message,
  },
});

export const updateTerms = (terms) => ({
  type: PROFILE.UPDATE_SERVICE_TERMS,
  payload: {
    terms,
  },
});
