export function isNullOrEmpty(stringValue) {
  return !stringValue;
}

export function equals(value, valueCompare) {
  return value === valueCompare;
}

export function isEmailValid(email) {
  // http://www.ietf.org/rfc/rfc5322.txt
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function hasMinLength(stringValue, length = 0) {
  return stringValue.length >= length;
}

export function hasLetters(stringValue) {
  return stringValue.match(/[A-Za-z]/gi);
}

export function hasNumbers(stringValue) {
  return stringValue.match(/[0-9]/gi);
}

export function isPasswordStrongEnough(stringValue) {
  // 8 characters minimum length
  // Use numbers and letters
  return hasMinLength(stringValue, 8) && hasLetters(stringValue) && hasNumbers(stringValue);
}

export function setErrorProps(message) {
  if (message) {
    return {
      kind: 'error',
      message,
    };
  }
  return {};
}

export function setStatusForm(status) {
  const production = process.env.NODE_ENV === 'production';
  if (production) {
    switch (status) {
      case 'success':
        return global.hj('formSubmitSuccessful');
      case 'failed':
        return global.hj('formSubmitFailed');
      default:
        return null;
    }
  } else {
    return null;
  }
}
