// Internal
import { appTypes } from 'app/shared/utils/actions';

const profile = appTypes.defineAction('profile');

// eslint-disable-next-line import/prefer-default-export
export const PROFILE = profile.defineAction('PROFILE', [
  'BY_ID',
  'BY_SLUG',
  'SIGNUP',
  'FETCH_CBOS',
  'FETCH_PARTNER',
  'FETCH_CBOS_SUCCESS',
  'FETCH_PARTNER_SUCCESS',
  'FETCH_FAILURE',
  'FETCH_SUCCESS',
  'FETCH_SUCCESS_SIGNUP',
  'FETCH_FAILURE_SIGNUP',
  'SET',
  'IS_FETCHING',
  'RESET',
  'UPDATE',
  'SAVE',
  'LIST_ADD',
  'LIST_REMOVE',
  'LIST_UPDATE',
  'BULK_UPDATE',
  'LIST_UPDATE_INSURANCES',
  'ACCEPT_SERVICE_TERMS',
  'ACCEPT_SERVICE_TERMS_SUCCESS',
  'ACCEPT_SERVICE_TERMS_FAILURE',
  'UPDATE_SERVICE_TERMS',
]);
