import { Clinic } from 'app/state/schedule/types';
import { ONLINE_PAYMENT_BETA_USERS } from 'app/shared/constants/queries';

export type Insurances = {
  name: string;
  id: number;
  accepts_in_person?: boolean;
  accepts_telemedicine?: boolean;
  allow_online_scheduling_in_person?: boolean;
  allow_online_scheduling_telemedicine?: boolean;
};

export const insurancePresentialSelect = (insurances: Insurances[]) =>
  insurances.filter(
    (ins) =>
      ins.accepts_in_person === true ||
      ins.allow_online_scheduling_in_person === true,
  );
export const insuranceTelemedicineSelect = (insurances: Insurances[]) =>
  insurances.filter(
    (ins) =>
      ins.accepts_telemedicine === true ||
      ins.allow_online_scheduling_telemedicine === true,
  );

export const insuranceStatusToggle = (insurances: Insurances[]) => {
  let attendancePersonStatus;
  const telemedicineSelect = insuranceTelemedicineSelect(insurances);
  const presentialSelect = insurancePresentialSelect(insurances);
  const isTelemedicineEmpty = telemedicineSelect.length === 0;
  const isPresentialEmpty = presentialSelect.length === 0;

  if (isTelemedicineEmpty) {
    attendancePersonStatus = true;
  }

  if (isPresentialEmpty) {
    attendancePersonStatus = false;
  }

  return attendancePersonStatus;
};

export const filterClinics = (acceptsInPerson: boolean, clinics: Clinic[]) => {
  if (acceptsInPerson) {
    return clinics.filter((clinic) => clinic.accepts_in_person === true);
  }

  return clinics.filter((clinic) => clinic.accepts_telemedicine === true);
};

export const userHasOnlinePayment = (id: number) =>
  ONLINE_PAYMENT_BETA_USERS.includes(id);
