export const IS_FETCHING = '@webapp-mkt/schedule/IS_FETCHING';
export const CHANGE_CLINIC_ID = '@webapp-mkt/schedule/CHANGE_CLINIC_ID';
export const FETCH_INSURANCES_CLINIC = '@webapp-mkt/schedule/FETCH_INSURANCES_CLINIC';
export const FETCH_INSURANCES_CLINIC_SUCCESS = '@webapp-mkt/schedule/FETCH_INSURANCES_CLINIC_SUCCESS';
export const FETCH_EVENTS = '@webapp-mkt/schedule/FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = '@webapp-mkt/schedule/FETCH_EVENTS_SUCCESS';
export const FETCH_CONFIGURATIONS = '@webapp-mkt/schedule/FETCH_CONFIGURATIONS';
export const FETCH_CONFIGURATIONS_SUCCESS = '@webapp-mkt/schedule/FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_EVENTS = '@webapp-mkt/schedule/FETCH_CONFIGURATIONS_EVENTS';
export const FETCH_CONFIGURATIONS_EVENTS_ERROR = '@webapp-mkt/schedule/FETCH_CONFIGURATIONS_EVENTS_ERROR';
export const FETCH_SAVE_EVENT = '@webapp-mkt/schedule/FETCH_SAVE_EVENT';
export const FETCH_SAVE_EVENT_SUCCESS = '@webapp-mkt/schedule/FETCH_SAVE_EVENT_SUCCESS';
export const FETCH_RESEND_EVENT = '@webapp-mkt/schedule/FETCH_RESEND_EVENT';
export const FETCH_CONFIRM_EVENT = '@webapp-mkt/schedule/FETCH_CONFIRM_EVENT';
export const FETCH_CONFIRM_EVENT_SUCCESS = '@webapp-mkt/schedule/FETCH_CONFIRM_EVENT_SUCCESS';
export const GO_TO_FIRST_STEP = '@webapp-mkt/schedule/GO_TO_FIRST_STEP';
export const HIDE_SCHEDULE = '@webapp-mkt/schedule/HIDE_SCHEDULE';
export const TOGGLE_MODAL_VISIBLE = '@webapp-mkt/schedule/TOGGLE_MODAL_VISIBLE';
export const OPEN_MODAL = '@webapp-mkt/schedule/OPEN_MODAL';
export const CLOSE_MODAL = '@webapp-mkt/schedule/CLOSE_MODAL';
export const CLEAR_STEP_LOADING = '@webapp-mkt/schedule/CLEAR_STEP_LOADING';
export const PUT_DEFAULT_CLINIC_ID = '@webapp-mkt/schedule/PUT_DEFAULT_CLINIC_ID';
export const FETCH_LAST_SCHEDULE = '@webapp-mkt/schedule/FETCH_LAST_SCHEDULE';
export const FETCH_LAST_SCHEDULE_SUCCESS = '@webapp-mkt/schedule/FETCH_LAST_SCHEDULE_SUCCESS';
export const CHANGE_ATTENDANCE = '@webapp-mkt/schedule/CHANGE_ATTENDANCE';
