import ReactGA from 'react-ga';

export const event = (options) => {
  if (process.browser && window.ga) {
    ReactGA.event({
      category: window.location.pathname,
      ...options,
    });
  }
};

export const pageView = (url) => {
  if (process.browser && window.ga) {
    ReactGA.pageview(url);
  }
};

export const init = (key) => {
  if (process.browser && window.ga) {
    ReactGA.initialize(key);
  }
};
